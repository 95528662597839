.trip-reservation-process-header {
  margin-top: 30px;
  margin-bottom: 45px;

  .reservation-steps {
    width:100%;
    border-bottom:1px solid #E1E6EB;
    text-align:center;
    color:#B3B7C1;
    display: flex;
    a {
      cursor: pointer;
    }
    .step {
      //width: 24%;
      //display:inline-block;
      flex-grow:1;
      flex-basis: 0;
      text-decoration: none;
      color:#B3B7C1;
      &:hover {
        text-decoration: none;
        p {
          color: #555;
        }
      }
      span {
        margin:0 auto;
        display:block;
        border-radius:100%;
        height:36px;
        width:36px;
        font-size:20px;
        line-height: 33px;
        border:2px solid #95db89;
        font-weight: 100;
      }
      p {
        text-align:center;
        line-height:1;
        margin:30px auto 25px;
      }
      &.active {
        p {
           border-bottom:2px solid #00abc9;
           padding-bottom:27px;
           margin-bottom:0px !important;
           color: #3c4043;
           font-weight:600;
          width: 200px;
        }
        span {
          border:2px solid #00abc9;
          background-color: #00abc9;
          color:#fff;
        }
      }
      &.todo span {
        background-color:none;
        color:#E1E6EB;
        border:2px solid #E1E6EB;
      }
      &.completed {
        p {
          color:#bsb7c1;
        }
        span {
          background-color:NONE;
          color:#95db89;
          border:2px solid #95db89;
          &:before {
            content: '\2713'
          }
        }
      }
    }
  }
}
