
.person-saved-persons {
  .tip {
    text-align:center;
    margin-bottom:15px;
  }
  .box-panel > .buttons {
    margin-top: 15px;
  }
  .person {
    padding: 10px;
    //border:1px solid #EDEDED;
    margin-bottom: 15px;
    position: relative;
    @media (max-width: $screen-sm-min) {
      height: auto;
    }
    .person-name {
        float: left;
        font-size:18px;
        font-weight: 300;
        color:#555;
        margin-left: 15px;
        width: calc(100% - 200px);
        @media (max-width: $screen-sm-min) {
          float: none;
          width: 100%;
          text-align: center;
          margin-left: 0px;
        }
    }
    .person-birthdate {
      float: left;
      font-size: 12px;
      font-weight: 300;
      margin-left: 15px;
      width: calc(100% - 200px);
      @media (max-width: $screen-sm-min) {
        float: none;
        width: 100%;
        text-align: center;
        margin-left: 0px;
      }
    }
    .person-birthplace {
      float: left;
      font-size: 12px;
      font-weight: 300;
      margin-left: 15px;
      width: calc(100% - 200px);
      @media (max-width: $screen-sm-min) {
        float: none;
        width: 100%;
        text-align: center;
        margin-left: 0px;
      }
    }
    .person-sex {
      float: left;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: #01b7f2;
      color: #FFF;
      font-size: 32px;
      @media (max-width: $screen-sm-min) {
        float: none;
        margin: 0px auto 10px;
      }
    }
    .buttons {
      position: absolute;
      right: 10px;
      top: 22px;
      @media (max-width: $screen-sm-min) {
        position: static;
        margin: 10px auto 10px;
        text-align: center;
        width: 100%;
        .btn {
          display: inline-block;
          float: none;
        }
      }
      .btn {
        min-width: 0px !important;
      }
    }
  }
}



.person-add {
  .buttons {
    margin-top:30px;
  }
}
