.regulations
{
    .box-panel
    {
        h1, h2
        {
            text-align: center;
        }

        h2
        {
            font-size: 18px;
        }

        pre
        {
            border: none;
            background: transparent;
            font-size: inherit;
        }

        code
        {
            font-family: $font-regular;
            color: $color-text;
        }
    }
}
