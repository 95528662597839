.trip-header {
  margin-bottom: 30px;
  &:after {
    content: "";
    clear: both;
    display: block;
  }
}

.trip-image {
  width: 120px;
  float: left;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 15px;
  img {
    width:100%;
  }
  @media (max-width: $screen-sm-min) {
      width: 100%;
      margin:15px 0px 30px;
      text-align: center;
      float: none;
      img {
        width: 150px;
      }
  }
}
.trip-details  {
  float: left;
  width:calc(100% - 250px);
  padding-left: 30px;
  border-left: 1px solid #DEDEDE;
  @media (max-width: $screen-sm-min) {
      width: 100%;
      float:none;
      padding-left: 0px;
      border-left: 0px;
      text-align: center;
  }
  .trip-title {
    font-size: 24px;
    font-weight: bold;
  }
  .trip-date {
    font-size: 18px;
    color:#777;
  }
  .trip-destination {
    font-size: 14px;
    color:#999;
  }
}
.trip-status {
  float: right;
  width: 130px;
  font-size: 14px;
  text-align: center;
  .status {
    display: block;
    margin-top: 1px;
    width: 100%;
    font-size: 14px;
    line-height: 32px;
  }
}
.trip-registration-until {
  font-size: 14px;
  color:#555;
  font-weight: 100;
}
.trip-price {
  color:$accent-green;
  font-size: 24px;
  margin-top:15px;
}

.trip-options {
  .form-group {
    margin-bottom: 30px;
    //height:90px;
  }
  .trip-option-title {
    font-size: 18px;
    font-weight: 600;
  }
  .trip-option-description {
    font-size: 12px;
    margin-bottom: 5px;
    color:#DF0B11;
  }
}
.trip-details-summary {

  label {
    font-size: 14px;
  }

  #trip-coupon-form {
    display: flex;
    #trip-coupon-remove-btn {
      display: none;
    }
    &.has-success {
      #trip-coupon-remove-btn {
        display: inline-block;
      }
      #trip-coupon-check-btn {
        display: none;
      }
    }
  }
}

.trip-payment-instruction {
  .box-panel {
    text-align: center;
  }

  .advance-possible,
  .advance-impossible{
    display: none;
  }

  .amount {
    margin:5px;
    text-align: center;
    .amount-val {
      font-weight: bolder;
      font-size: 20px;
    }
  }
}

#trip-total-price {
  padding: 15px;
  background: $accent-green;
  color: #FFF;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  border-radius: 8px;
}
.trip-terms-content {
  max-height: 250px;
  width: 100%;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: justify;
  padding-right: 18px;

  h1,h2,h3,h4,h5,h6 {
    font-weight: bolder;
    text-align: center;
  }

  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
}
.trip-terms-agree {
  width: 280px;
  margin: 10px auto;
  background: #EDEDED;
  padding: 5px 5px 0px;
  border-radius: 5px;

  #regulations-inp.error + label + label {
    color: #a94442;
  }
  #regulations-error {
    display: none !important;
  }
}
