.reservations {
  .reservations-content {
    min-height: 300px;
    &:after {
      content: "";
      clear:both;
      display: block;
    }
    .reservation {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $border-color;
      display: block;

      &:after {
        content: "";
        clear:both;
        display: block;
      }
      .reservation-icon {
        width: 90px;
        height: 90px;
        float: left;
        background: $accent-grey;
        margin-right: 15px;
        .header {
          background: $accent-blue;
          height: 15px;
          line-height: 15px;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
          color: #FFF;
          font-size: 9px;
          letter-spacing: 0.5px;
        }
        .trip {
          font-size: 32px;
          line-height: 32px;
          text-align: center;
          margin-top: 15px;
          color: $accent-blue;
          font-weight: bold;
        }
        .year {
          margin-top: 0px;
          color: #999;
          font-size: 12px;
          text-align: center;
        }
      }
      .reservation-details {
        float: left;
        width: 260px;
        border-right: 1px solid lighten($border-color, 8%);
        margin-right: 15px;
        height: 90px;
        .name {
          font-size: 16px;
          font-weight: bolder;
          color: #555;
        }
        .dates {
          font-size: 14px;
          color: #999;
        }
        .destination {
          font-size: 12px;
          color: #999;
        }
        .status {
          margin-top: 5px;
          color: #555;
          font-size: 12px;
          .label {
            color: #FFF;
            font-weight: 200;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 0.8px;
            margin-left: 5px;
          }
        }
      }
      .reservation-participant {
        float: left;
        margin-top: 5px;
        .res-id {
          font-size: 11px;
          color: $accent-green;
        }
        .name {
          font-size: 14px;
          font-weight: bolder;
          color: #555;
        }
        .birthdate {
          font-size: 12px;
          color: #555;
        }
        .button {
          margin-top: 5px;
          a {
            width: 80px;
          }
        }
      }

    }
  }
}
