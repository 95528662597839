body
{
    font-family: $font-regular;
    background-color:#1a232f;
}

header {
    margin-top: 15px;
    margin-bottom: 15px;
    height: 40px;
    position: relative;
    .logo {
      float: left;
    }
    .account {
      float: right;
      position: absolute;
      bottom: 0px;
      right: 0px;
      .logout-form {
        display: none;
      }
      .dropdown-toggle {
        cursor: pointer;
        span {
          line-height: 38px;
          margin-right: 5px;
          color: #FAFAFA;
        }
        .icon {
          float: right;
          width:38px;
          height:38px;
          line-height: 39px;
          text-align: center;
          display: block;
          border-radius: 100%;
          background: #FAFAFA;
          font-size: 20px;
          color:#CCC;
        }
        &:hover {
          text-decoration: none;
          .icon {
            color:#999;
          }
        }
      }
    }
}

a {
  cursor: pointer;
}

.form-control{
  -webkit-appearance: none;
}

.alert {
  text-align: center;
}

label.error {
  color: #a94442;
  font-size: 12px;
}

#main-container
{
    max-width: 900px;

    #main-content
    {
        background: #FAFAFA;
        padding: 30px;

        @media (max-width: $screen-sm-min) {
            padding: 15px;
        }

        .title
        {
            color: $color-text;
            font-size: 24px;
            line-height: 26px;
        }

        .subtitle
        {
            color: $color-text;
            font-size: 13px;
            font-weight: 100;
        }

        .box-panel
        {
            background: #fff;
            color: $color-text;
            font-size: 14px;
            font-weight: 100;
            border: 1px solid $border-color;
            border-radius: 8px;
            margin-top: 5px;
            margin-bottom: 30px;
            padding: 30px;

            @media (max-width: $screen-sm-min) {
                padding:15px;
            }

            b
            {
                font-weight: 600;
            }
        }

        .buttons
        {
            text-align: center;

            .btn
            {
                min-width: 120px;
            }
        }
    }
}

.mini-footer {
  height:30px;
  margin-top:15px;
  margin-bottom: 15px;
  color:#FAFAFA;
  font-size: 12px;
  text-align: center;
  a {
    color:inherit;
  }
  .powered {
    color:#888;
    a {
      color: #BBB;
    }
  }
}
