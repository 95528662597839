.jobs {
  .box-panel {
    background: #FFF;
  }

  .upload-doc-box {
	position: relative;
	overflow: hidden;
    .upload-doc-box-file-input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        width:100%;
        height:100%;
        z-index: 3;
    }
  }

  .job-summary-details {
      padding-bottom: 15px;
      .field {
          font-size: 11px;
          font-weight: bolder;
          color: #999;
          text-transform: uppercase;
      }
      .position-name {
          font-size: 18px;
          letter-spacing: 1px;
      }
      ul  {
          margin-top:5px;
          padding-left: 18px;
          li {
              
          }
      }
  }
}


