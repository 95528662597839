.summary
{
  .box-panel
  {
    background: #FFF;
  }

  .trip-image
  {
    margin: 15px 0 30px;

    >img
    {
      width: 100%;
      max-width: 150px;
    }
  }

  .summary-trip
  {
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-align: center;
    
    &:after
    {
      content: "";
      clear:both;
      display: block;
    }

    .trip-title
    {
      font-size: 20px;
      font-weight: 700;
    }

    .trip-date
    {
      font-size: 16px;
    }

    .trip-destination
    {
      font-size: 12px;
    }

    .trip-status
    {
      float: none;
      width: 100%;
      margin: 15px 0;

      .label
      {
        display: block;
        font-size: 14px;
        line-height: 25px;
        max-width: 150px;
        margin: 0 auto;
      }
    }

    .trip-payment
    {
      .btn
      {
        display: block;
        width: 100%;
        max-width: 150px;
        margin: 0 auto;
      }
    }
  }

  .summary-details {

    .empty {
      text-align: center;
      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 15px;
    }
    .text-center {
      text-align: center;
    }

    .payments-details {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid $border-color;
      margin-bottom: 15px;
      font-size: 14px;
      .title {
        font-weight: bold;
        font-size: 14px !important;
        line-height: 20px !important;
      }
      .subtitle {
        font-weight: bold;
        font-size: 0.5em;
      }
      .iban {
        margin-top: 15px;
        font-size: 13px;
        font-weight: bolder;
      }
      .saldo-text {
        text-align: left;
        font-size: 20px;
        font-weight: bolder;
      }
      .saldo-value {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
      }
    }

    margin-bottom: 30px;
    .title {
      font-size: 16px !important;
      font-weight: 400;
    }
    .summary-details-content {
      padding: 20px 15px 0px 15px;
      background: #FFF;
      border: 1px solid #EDEDED;
      border-radius: 8px;
      .summary-list-header {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #EDEDED;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: bold;
      }
      .summary-list-element {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #EDEDED;
        .option,
        .name {
          font-weight: 400;
        }
        .description {
          font-size: 12px;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
  .summary-person {
    .person-details {
      font-size: 12px;
      .person-detail {
        margin-bottom: 15px;
        &.person-address {
          margin-bottom: 25px;
        }
        .field {
          //font-weight: bold;
          text-transform: uppercase;
          font-size: 10px;
          color: #999;
        }
        .value {
          color: #555;
          font-size: 14px;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .summary-totals {
    .summary-totals-list {
      .summary-list-element:last-child .total {
        font-weight: 600;
      }
    }
  }
  .summary-comment {
    .summary-comment-content {
      textarea {
        height:100px;
        margin-right: 5px;
        margin-bottom: 15px;
        width: 100%;
        max-width: 100%;
        max-height: 200px;
        border:0;
        outline: 0;
        &:focus {
          outline: 0;
        }
      }
    }
  }
  .summary-agreements {
    .summary-agreement {
      margin-bottom: 15px;
      &:after {
        clear:both;
        content: "";
        display: block;
      }
      input {
        float: left;
      }
      label {
        font-weight: 100;
        margin-left: 10px;
        font-size: 13px;
        letter-spacing: 0.3px;
        float: right;
        width: calc(100% - 30px);
        &.error {
          float: right;
          margin-top: 1px;
        }
      }
    }
  }
  .summary-documents {
    margin-bottom: 15px;
    .summary-document {
      color: #555;
      width: 100%;
      padding: 15px;
      border: 1px solid #EDEDED;
      display: block;
      margin-bottom: 15px;
      border-radius: 8px;
      &:hover {
        background: #FAFAFA;
      }
      .summary-document-icon {
        text-align: center;
        margin-bottom: 10px;
        i {
          font-size: 64px;
        }
      }
      .summary-document-name {
        text-align: center;
        font-size: 14px;
      }
    }
  }


  .big-icon {
      text-align: center;
      font-size: 64px;
      line-height: 122px;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      margin: 30px auto 15px;
  }
  .big-text {
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 45px;
  }

  &.success {
    .big-icon {
      color:#59d044;
      border: 3px solid #59d044;
    }
    .big-text {
      color:#59d044;
    }
  }

  &.info {
    .big-icon {
      color:#45a3f3;
      border: 3px solid #45a3f3;
    }
    .big-text {
      color:#45a3f3;
    }
  }

  &.danger {
    .big-icon {
      color:#a94442;
      border: 3px solid #a94442;
    }
    .big-text {
      color:#a94442;
    }
  }
  
  @media (min-width: $screen-sm-min)
  {
    .summary-trip
    {
      text-align: left;

      .trip-status
      {
        margin: 0 0 15px;
      }
    }

    .trip-image
    {
      margin: 0;
    }
  }
}
