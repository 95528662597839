
// Fonts
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700);

// Variables
$font-regular: 'Titillium Web', sans-serif;

$color-text: #555;

$accent-green: #5cb85c;
$accent-blue: #5bc0de;
$accent-grey: #f5f5f5;
$border-color: #DEDEDE;

// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3";

@import "common";

@import "parts/reservation_process_header";
@import "parts/regulations";

@import "pages/trip";
@import "pages/login";
@import "pages/person";
@import "pages/summary";
@import "pages/reservations";
@import "pages/jobs";